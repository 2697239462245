import styled from 'styled-components';

import { BorderMuted } from '@components/BorderMuted';
import { useAppContext } from '@contexts/AppContext';
import { MixpanelEvents, Page } from '@entities/enums';
import { useMediaIs } from '@hooks/useMediaIs';

import { CTAButton, Heading3, Margin, P, Strong } from '@ovotech/element';
import { useCustomMixpanel } from '@services/Mixpanel';
import { mqSmallUp } from '@utils/mediaQuery';
import { PrimaryCTA } from '@components/PrimaryCTA';
import { SupRef } from '@components/Footer';
import { homeRecoverFreeRepair } from '@constants/footnotes';

const GreenText = styled.span`
  ${({ theme }) => `
    color: ${theme.semantic.message.branded};
  `}
`;

const Wrapper = styled('div')`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;
    gap: ${theme.core.space[3]}; 
    
    > div:nth-child(odd) {
        flex: 1;
      }
  `}
`;

const FreeRepairFooter = styled.div`
  background-color: ${({ theme }) => theme.core.color.green.lightest};
  width: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.core.fontWeight.bold};
`;

const BannerText = styled(P)`
  ${mqSmallUp('min-height: 80px;')}
`;

const StyledHeading3 = styled(Heading3)`
  ${({ theme }) => `
    color: ${theme.semantic.message.base};
    font-weight: ${theme.core.fontWeight.bold};
  `}
`;

const NotLoggedInContainer = styled('div')`
  ${({ theme }) => `
    border-left: 1px solid ${theme.core.color.neutral.lighter};
   
  `}
`;

const StyledBannerWrapper = styled.div`
  padding: 24px;
  padding-top: 0;
  button {
    max-width: 300px;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const HeadingContainer = styled.div`
  padding: 24px;
  padding-bottom: 0;
`;

const CustomerLogInSection = () => {
  const { navigateToLogin } = useAppContext();
  const { track } = useCustomMixpanel();

  return (
    <StyledBannerWrapper>
      <BannerText>
        <Strong>Already an OVO customer?</Strong>
        <Margin vertical={1} />
        <P>
          Log in to get your{' '}
          <GreenText>
            <strong>free</strong>{' '}
          </GreenText>
          repair + our Complete cover.{' '}
        </P>
      </BannerText>
      <Margin bottom={4} />
      <Centered>
        <PrimaryCTA
          fullWidth
          variant="primary"
          onClick={() => {
            track(MixpanelEvents.BUTTON_CLICK, {
              buttonId: 'getYourFreeRepair',
              pageName: 'home-recover',
            });
            navigateToLogin(Page.HOME_RECOVER);
          }}
        >
          Get your free repair
        </PrimaryCTA>
      </Centered>
    </StyledBannerWrapper>
  );
};

const NotCustomerText = ({
  onContinue,
  freeRepair,
}: {
  onContinue: () => void;
  freeRepair: boolean;
}) => {
  const { track } = useCustomMixpanel();

  return (
    <StyledBannerWrapper>
      <BannerText>
        <strong>Not with OVO?</strong>
        <Margin vertical={1} />
        {!freeRepair ? (
          <P>
            Get your repair for{' '}
            <GreenText>
              <Strong>£79</Strong>{' '}
            </GreenText>
            when you take out our Complete cover.
          </P>
        ) : (
          <P>
            For a limited time get a{' '}
            <GreenText>
              <Strong>free</Strong>
            </GreenText>{' '}
            repair, normally £79, when you take out Complete cover.
          </P>
        )}
      </BannerText>
      <Margin vertical={4} />
      <Centered>
        <CTAButton
          fullWidth
          data-testid="repair-login-banner-continue"
          variant="secondary"
          type="button"
          onClick={() => {
            track(MixpanelEvents.BUTTON_CLICK, {
              buttonId: 'bookRepair79',
              pageName: 'home-recover',
            });
            onContinue();
          }}
        >
          {freeRepair ? 'Get your free repair' : 'Book repair for £79'}
        </CTAButton>
      </Centered>
    </StyledBannerWrapper>
  );
};

export const RepairLoginBanner = ({
  onContinue,
  freeRepair,
}: {
  onContinue: () => void;
  freeRepair: boolean;
}) => {
  const isMobile = useMediaIs('mobile', 'max');

  if (isMobile) {
    return (
      <div>
        <HeadingContainer>
          <StyledHeading3>Get a repair +</StyledHeading3>
          <StyledHeading3>our Complete cover.</StyledHeading3>
        </HeadingContainer>

        <Margin vertical={6} />
        <CustomerLogInSection />
        <Margin vertical={6} />
        <BorderMuted />
        <Margin vertical={5} />
        <NotCustomerText onContinue={onContinue} freeRepair={freeRepair} />
        <Margin vertical={3} />
        {freeRepair && (
          <FreeRepairFooter>
            Free initial repair <SupRef footnote={homeRecoverFreeRepair} />
          </FreeRepairFooter>
        )}
      </div>
    );
  } else {
    return (
      <div data-testid-id="wrapper">
        <HeadingContainer>
          <StyledHeading3>Get a repair + our Complete cover</StyledHeading3>
        </HeadingContainer>
        <Margin vertical={6} />
        <Wrapper>
          <CustomerLogInSection />
          <NotLoggedInContainer data-testid="not-customer-wrapper">
            <NotCustomerText onContinue={onContinue} freeRepair={freeRepair} />
            {freeRepair && (
              <FreeRepairFooter>
                Free initial repair
                <SupRef footnote={homeRecoverFreeRepair} />
              </FreeRepairFooter>
            )}
          </NotLoggedInContainer>
        </Wrapper>
      </div>
    );
  }
};
