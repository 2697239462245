import { navigate } from 'gatsby';
import { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { BasicPage, Basket, ExcessPriceText, StyledCard } from '@components';
import { SupRef } from '@components/Footer';
import {
  LOGGED_IN_REPAIR_COST,
  LOGGED_OUT_REPAIR_COST,
} from '@constants/homeRepair';
import { ROUTE_HOME_RECOVER_REPAIR_DETAILS } from '@constants/routes';
import { useModalContext } from '@contexts/ModalContext';
import { CoverType, MixpanelEvents, ProductName } from '@entities/enums';
import { summaryItems } from '@fixtures';
import {
  Heading1,
  Heading3,
  Icon,
  Margin,
  P,
  Strong,
  TextLink,
} from '@ovotech/element';
import { WindowLocation } from '@reach/router';
import { useCustomMixpanel } from '@services/Mixpanel';
import PromoService from '@services/PromoService';
import StoreService from '@services/StoreService';
import { mqLargeUp } from '@utils/mediaQuery';

import { FindOutMore } from '../PlanSelection/components';

import { RepairLoginBanner } from './components/RepairLoginBanner';
import { useAuth } from '@hooks/useAuth';
import useQuote from '@hooks/useQuote';
import { usePromoComponents } from '@hooks/usePromoComponents';
import { useMediaIs } from '@hooks/useMediaIs';
import { DONT_NEED_COVER } from '@constants/OVOUrls';
import { defaultFootnotes } from '@constants/footnotes';
import { completePlanItems, sevenDaysAWeekItems } from '@fixtures/summaryItems';
import { useAppContext } from '@contexts/AppContext';
import { getRedirectedExcessValue } from '@utils/excess';
import { DataLayer } from '@services/DataLayerService';

const IconWrapper = styled.div`
  margin-right: ${(props) => props.theme.core.space[2]};
  display: flex;
  align-items: center;
`;

const PlanCost = styled.div`
  display: flex;
  align-items: baseline;
`;

const SummaryItemName = styled.span`
  margin: 0;
`;

const SevenDaysSummaryItemListWrapper = styled.div(
  () => css`
    display: block;

    ${mqLargeUp(`
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      grid-auto-flow: column;
    `)}
  `
);

const CompletePlanSummaryItemListWrapper = styled.div(
  () => css`
    display: block;

    ${mqLargeUp(`
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-auto-flow: column;
    `)}
  `
);

const SummaryItemListWrapper = styled.div(
  () => css`
    display: block;

    ${mqLargeUp(`
      display: grid;
      grid-template-rows: repeat(6, 1fr);
      grid-auto-flow: column;
    `)}
  `
);

const SummaryItemWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: ${theme.core.space[2]};
    }

    svg {
      color: ${theme.core.color.green.light};
    }
  `
);

const StyledLeft = styled(BasicPage.Left)`
  padding-left: 0;
`;

const LeftPriceCard = styled(StyledCard)`
  padding: 0;
`;

const StyledHeading3 = styled(Heading3)`
  ${({ theme }) => `
    color: ${theme.semantic.message.base};
    font-weight: ${theme.core.fontWeight.bold};
  `}
`;
const MainHeading = styled(Heading1)`
  padding-left: 0;
`;

const CoverWrapper = styled.div(
  ({ theme }) => css`
    font-size: ${theme.core.fontSize.body};
    margin-left: ${theme.core.space[4]};
  `
);

interface Props {
  location: WindowLocation;
}

export const HomeRecoverOverview = (props: Props) => {
  PromoService.clear();
  const { ModalComponent } = useModalContext();

  const { promo, excess, setExcess } = useAppContext();

  const { isHomeRecover } = StoreService.load();
  const { user, loading } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const { track } = useCustomMixpanel();
  const {
    getHomeRecoverBanner,
    getHomeRecoverPromoBadge,
    getHomeRecoverPriceHeading,
    getHomeRecoverPriceSubHeading,
  } = usePromoComponents();

  const isMobile = useMediaIs('mobile', 'max');

  const { quotes } = useQuote({
    excess,
    promo,
    boilerAge: parseInt(StoreService.load().boilerDetails.boilerAge),
    isHomeRecover,
  });

  const quoteComplete = quotes.find(
    (quote) => quote.coverType === CoverType.COMPLETE
  );

  const hasFreeRepairOffer = quoteComplete?.promotions.some(
    (offer) => offer.type === 'FREEREPAIR'
  );

  const isLoggedIn = Boolean(user?.accountId && !loading);

  const repairCost =
    isLoggedIn || hasFreeRepairOffer
      ? LOGGED_IN_REPAIR_COST
      : LOGGED_OUT_REPAIR_COST;

  useEffect(() => {
    StoreService.save({
      isHomeRecover: true,
      isBeyond: false,
      isAbs: false,
      repair: {
        cost: repairCost,
      },
      isAgent: false,
      excess: getRedirectedExcessValue(excess),
    });
    setExcess(getRedirectedExcessValue(excess));
  }, [repairCost]);

  useEffect(() => {
    track(MixpanelEvents.PAGE_HOME_RECOVER_OVERVIEW, { pageName: null });
  }, [track]);

  useEffect(() => {
    DataLayer.push([
      {
        event: 'selectedProductName',
        ProductName: ProductName.HOME_RECOVER,
      },
      {
        event: 'selectedCoverType',
        CoverType: CoverType.COMPLETE,
      },
    ]);
  }, []);

  const onContinue = useCallback(() => {
    StoreService.save({
      selectedCoverType: quoteComplete?.coverType,
    });

    navigate(ROUTE_HOME_RECOVER_REPAIR_DETAILS);
  }, [quoteComplete]);

  if (!quoteComplete) return null;

  return (
    <BasicPage
      pageName="home-recover"
      quote={quoteComplete}
      location={props.location}
    >
      <ModalComponent />
      {getHomeRecoverBanner(quoteComplete.promotions)}
      <StyledLeft id="left">
        <Margin vertical={6} />
        <MainHeading>Home recover</MainHeading>
      </StyledLeft>

      <BasicPage.Content>
        <BasicPage.Left>
          {isLoggedIn ? null : (
            <>
              <LeftPriceCard>
                <RepairLoginBanner
                  onContinue={onContinue}
                  freeRepair={Boolean(hasFreeRepairOffer)}
                />
              </LeftPriceCard>
              <Margin vertical={6} />
            </>
          )}
          {isMobile && (
            <>
              <Basket
                quote={quoteComplete}
                isLoggedIn={isLoggedIn}
                isSubmitting={false}
                location={props.location}
                onClick={onContinue}
                nextText={'Continue'}
                repairCost={repairCost}
                displayExcessText={true}
                isHomePage
              />
            </>
          )}
          <Margin vertical={6} />
          <StyledCard>
            <StyledHeading3>What you can get repaired?</StyledHeading3>
            <Margin vertical={8} />
            <Strong>7 days a week</Strong>
            <Margin vertical={2} />
            <P>
              Buy before 1pm and we&apos;ll aim to contact you today, otherwise
              we&apos;ll usually call within 24 hrs.
            </P>
            <Margin vertical={4} />
            <SevenDaysSummaryItemListWrapper>
              {sevenDaysAWeekItems.map((item) => {
                return (
                  <SummaryItemWrapper key={item.id}>
                    <IconWrapper>
                      <Icon name={item.icon} size={16} />
                    </IconWrapper>
                    <Margin right={2} inline>
                      <SummaryItemName>{item.name}</SummaryItemName>
                    </Margin>
                  </SummaryItemWrapper>
                );
              })}
            </SevenDaysSummaryItemListWrapper>
            <Margin vertical={6} />
            <Strong>{quoteComplete?.productName} plan</Strong>
            <Margin vertical={2} />
            <PlanCost>
              {quoteComplete.promotions.map((promo) =>
                getHomeRecoverPriceHeading(quoteComplete, promo)
              )}
              {quoteComplete.promotions.map((promo) =>
                getHomeRecoverPromoBadge(promo)
              )}
              {!quoteComplete.discountApplied && (
                <>
                  From £{quoteComplete.price} a month
                  <SupRef
                    footnote={{
                      ...defaultFootnotes.noClaims,
                      id: 'claims-terms',
                    }}
                  />
                  <ExcessPriceText
                    quote={quoteComplete}
                    isHomeRecover={isHomeRecover}
                  />
                </>
              )}
            </PlanCost>
            {quoteComplete.promotions.map((promo) =>
              getHomeRecoverPriceSubHeading(quoteComplete, promo)
            )}
            <Margin vertical={4} />
            <CompletePlanSummaryItemListWrapper>
              {completePlanItems.map((item) => {
                return (
                  <SummaryItemWrapper key={item.id}>
                    <IconWrapper>
                      <Icon name={item.icon} size={16} />
                    </IconWrapper>
                    <Margin right={2} inline>
                      <SummaryItemName>{item.name}</SummaryItemName>
                    </Margin>
                  </SummaryItemWrapper>
                );
              })}
            </CompletePlanSummaryItemListWrapper>
            <Margin vertical={6} />
            <Strong>Your plan covers</Strong>
            <Margin vertical={4} />
            <SummaryItemListWrapper>
              {summaryItems.map((item) => {
                return (
                  <SummaryItemWrapper key={item.id}>
                    <IconWrapper>
                      <Icon name={item.icon} size={16} />
                    </IconWrapper>
                    <Margin right={2} inline>
                      <SummaryItemName>{item.name}</SummaryItemName>
                    </Margin>
                    <FindOutMore item={item} />
                  </SummaryItemWrapper>
                );
              })}
            </SummaryItemListWrapper>
          </StyledCard>
        </BasicPage.Left>
        <BasicPage.Right>
          {!isMobile && (
            <Basket
              quote={quoteComplete}
              isLoggedIn={isLoggedIn}
              isSubmitting={false}
              location={props.location}
              onClick={onContinue}
              nextText={'Continue'}
              repairCost={repairCost}
              displayExcessText={true}
              isHomePage
            />
          )}
          {isMobile && (
            <CoverWrapper>
              <TextLink
                href={DONT_NEED_COVER}
                aria-label="Don't need cover"
                onClick={() => {
                  track(MixpanelEvents.BUTTON_CLICK, {
                    buttonId: 'dontNeedCover?',
                  });
                }}
              >
                Don&apos;t need cover?
              </TextLink>
            </CoverWrapper>
          )}
        </BasicPage.Right>
      </BasicPage.Content>
    </BasicPage>
  );
};
